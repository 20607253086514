import createDataContext from './createDataContext';
import API from '../api/api';
import { Reporter } from 'gatsby';

// Return what's needed on each case
const videoplayerReducer = (state, action) => {
    switch (action.type) {
        case 'get_shows':
            return {
                ...state,
                shows: action.payload
            }
        case 'get_show':
            return {
                ...state,
                showId: action.payload.id,
                showName: action.payload.name,
                displayName: action.payload.displayName,
                performances: action.payload.performances
            }
        case 'get_performance_configuration':
            return {
                ...state,
                initialStreamIndex: action.payload.initialStreamIndex,
                programStreamIndex: action.payload.programStreamIndex,
                buttonsStreamIndex: action.payload.buttonsStreamIndex,
                subtitleStreamIndex: action.payload.subtitleStreamIndex,
                signingStreamIndex: action.payload.signingStreamIndex,
                buttons: action.payload.buttons,
                alternateAudioStreams: action.payload.alternateAudioStreams
            };
        case 'get_stream':
            return {
                ...state,
                performanceId: action.payload.id,
                streams: action.payload.streams
            };
        case 'redeem_ticket':
            return {
                ...state,
                serverError: action.payload.error,
                streams: action.payload.performanceStreams,
                reportUsageInterval: action.payload.reportUsageInterval,
                ticketSessionId: action.payload.ticketSessionId
            };
        case 'server_time':
            return {
                ...state,
                serverTime: action.payload
            };
        case 'get_performance':
            return {
                ...state,
                showId: action.payload.id,
                showName: action.payload.name,
                displayName: action.payload.displayName,
                performances: action.payload.performances
            }
        case 'is_synced':
            return {
                ...state,
                isSynced: true,
            }
        default:
            return state;
    }
};

// Retrieves data for all shows, may not be required.
const getShows = dispatch => async () => {
    try {
        const shows = await API.get('/shows');
        dispatch({ type: 'get_shows', payload: shows.data });
        return shows.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
}

// Can receive the ID or the name of the show
// Likely the first method called upon loading the page, the show name should be extracted from the URL.
const getShow = dispatch => async (name) => {
    try {
        const show = await API.get(`/shows/${name}`);
        dispatch({ type: 'get_show', payload: show.data });
        return show.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        return "";
    }
}

// Can receive the ID or the name of the performance
// Likely the first method called upon loading the page, the performance name should be extracted from the URL.
const getPerformance = dispatch => async (id) => {
    try {
        const performance = await API.get(`/shows/performance/${id}`);
        dispatch({ type: 'get_performance', payload: performance.data });
        return performance.data;
    } catch (err) {
        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        return "";
    }
}

// Should be called to get the performance configuration once the correct performance ID has been found.
const getPerformanceConfiguration = dispatch => async (id) => {
    try {
        const configuration = await API.get(`/configurations/${id}`);
        dispatch({ type: 'get_performance_configuration', payload: configuration.data });
        return configuration.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
};

// Deprecated. Provides the final piece of information required to view the streams.
const getStream = dispatch => async (id) => {
    try {
        const response = await API.get(`/streams/${id}`,
        );
        dispatch({ type: 'get_stream', payload: response.data });
        return response.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
}

const reportUsage = dispatch => async (id) => {
    try {
        await API.get(`/tickets/reportusage/${id}`,
        );
        return;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
}

const redeemTicket = dispatch => async (performanceId, id) => {
    try {
        const response = await API.get(`/tickets/redeem/${performanceId}/${id}`,);
        dispatch({ type: 'redeem_ticket', payload: response.data });

        return response.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
}

const getServerTime = dispatch => async () => {
    try {
        const response = await API.get(`/servertime`,);
        dispatch({ type: 'server_time', payload: response.data });
        return response.data;
    } catch (err) {

        console.log(err);
        if (err.response) {
            console.log(err.response.data.message);
        }
        throw err;
    }
}

const checkSync = dispatch => () => {
    dispatch({ type: 'is_synced' });
}

export const { Context, Provider } = createDataContext(
    videoplayerReducer,
    {
        checkSync,
        getShows,
        getShow,
        getPerformance,
        getPerformanceConfiguration,
        getStream,
        reportUsage,
        redeemTicket,
        getServerTime
    },
    {
        shows: [],
        showId: '',
        showName: '',
        displayName: '',
        performances: [],
        initialStreamIndex: null,
        programStreamIndex: null,
        buttonsStreamIndex: null,
        subtitleStreamIndex: null,
        signingStreamIndex: null,
        buttons: [],
        alternateAudioStreams: [],
        performanceId: '',
        configurationId: '',
        serverError: null,
        streams: [],
        reportUsageInterval: null,
        ticketSessionId: '',
        serverTime: null,
        isSynced: false,
    }
);