import axios from 'axios';
import axiosRetry from 'axios-retry';
// Create axios instance
const instance = axios.create({
    baseURL: `https://api.rivver.uk/api`,
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
axiosRetry(instance, { retries: 3 });

// Create interceptors for the request
instance.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (err) => {
        return Promise.reject(err);
    }
);

// Create interceptor for the response
instance.interceptors.response.use(
    async (response) => {
        return response;
    },
    (err) => {
        console.log(err);
        return Promise.reject(err);
    }
);

export default instance;